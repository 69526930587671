$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

function ajaxCallCustom(url, type, data, redirect) {
    $.ajax({
        url: url,
        type: type,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 800000,
        data: data,
        beforeSend: function () {
            Swal.fire({
                title: 'Loading...',
                text: 'I will close soon...',
                showCancelButton: false,
                showConfirmButton: false
            })
        },
        success: function (data) {
            Swal.fire(
                'Good job!',
                'Operation completed successfully!',
                'success'
            ).then(function () {
                window.location.href = redirect;
            });
        },
        error: function (data) {
            console.log(data);
            Swal.fire(
                'Error!',
                'Please contact the administrator!',
                'error'
            )
        }
    });
}

$('body').on('submit', 'form[name=delete-item]', function(e) {
    e.preventDefault();

    Swal.fire({
        title: 'Sei sicuro di voler procedere?',
        showCancelButton: true,
        confirmButtonText: 'Confermo',
        cancelButtonText: 'Indietro',
        icon: 'warning'
    }).then((result) => {
        if (result.value) {
            this.submit()
        }
    });
});
